// DEV WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:40529db9-36ba-4d03-9f76-f612521b5d6d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_zkFXjeY7d",
    "aws_user_pools_web_client_id": "2l3b4nv57l3klpmsvducvh12n",
    "oauth": {},
    "aws_user_files_s3_bucket": "forex-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};

export default awsmobile;